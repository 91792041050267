import React, { useState } from "react";
import { Container, Spinner, Tab, Tabs } from "react-bootstrap";
import { AiOutlineDownload } from "react-icons/ai";
import { MdOutlineFileDownloadOff } from "react-icons/md";
import { useSelector } from "react-redux";
import TableComp from "../../components/Table";
import config from "../../constants/config";
import { getDrivers } from "../../rtk-slice/globalSlice";
import { generateDriversEditViewDeleteColumn } from "../../utils";
import { downloadFileFromBlob, fetchService } from "../../utils/api";
import { errorToast } from "../../utils/toastFunc";
import {
  NoteCell,
  wrapCell,
} from "../SurchargeMaintenance/components/CommonCell";
import { ColumnFilter } from "../UserManagement/UsersDisplay";
import NotInRunsheetsDriversModal from "./Modals/NotInRunsheetsDriversModal";
import SelectedDriversModal from "./Modals/SelectedDriversModal";
import GroupByColumnPie from "./charts/GroupByColumnPie";
import LicenseExpiryRatioPie from "./charts/LicenseExpiryRatioPie";
import LineGraphWithOptions from "./charts/LineGraphWithOptions";
import VehiclesTypeChart from "./charts/VehiclesTypeChart";
import "./driversDashboard.scss";
import { ArrayFilters } from "./helpers/ArrayFilters";
import { driversTableClickEvents } from "./utils";

const getExpirationColor = (expiryDateStr) => {
  const today = new Date();
  const expiry = new Date(expiryDateStr);

  // Calculate the difference in days
  const diffTime = expiry - today;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays < 0) {
    return { color: "#c98586", background: "#ffe1dc", tooltip: "EXPIRED" }; // Expired
  } else if (diffDays <= 30) {
    return {
      color: "#cca65e",
      background: "#fff1c7",
      tooltip: "EXPIRING SOON",
    }; // Expiring soon (e.g., within 30 days)
  } else {
    return { color: "#699670", background: "#ddffe4", tooltip: "ACTIVE" }; // Active
  }
};

const DownloadDocumentButton = ({
  docName,
  userId,
  url = null,
  fileName = null,
}) => {
  const [loading, setLoading] = useState(false);

  const URL = url ? url : config.downloadInductionDoc + docName;

  const handleDownload = async () => {
    setLoading(true);
    fetchService({
      method: "GET",
      url: URL,
      isFile: true,
    })
      .then((response) => {
        if (response.status === 200) return response.blob();
        else errorToast({ mes: "Error downloading the file" });
      })
      .then((stream) => {
        downloadFileFromBlob(stream, fileName ? fileName : docName);
      })
      .catch((error) => {
        errorToast({
          mes: error?.message?.split?.(":")?.[1] ?? "Something went wrong!",
        });
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  if (!docName)
    return (
      <button
        // disabled
        className="btn btn-error no-document-btn w-100"
        style={{ color: "red" }}
      >
        <MdOutlineFileDownloadOff />
      </button>
    );

  return (
    <button className="btn btn-info w-100" onClick={handleDownload}>
      {loading ? (
        <Spinner
          style={{ width: "16px", height: "16px" }}
          animation="border"
          variant="primary"
        />
      ) : (
        <AiOutlineDownload />
      )}
    </button>
  );
};

const DriversDetailCard = ({ title, value, children }) => (
  <div className="shadow-sm card card-hover-shadow h-100">
    <div className="card-body">
      <h6 className="card-subtitle">{title}</h6>
      <div className="d-flex justify-content-center mt-3">
        {children ?? <h2 className="card-title text-inherit">{value}</h2>}
      </div>
    </div>
  </div>
);

const selectedDriversInitialState = {
  drivers: [],
  header: "",
};

export default function DriversDashboardPage() {
  const drivers = useSelector(getDrivers);

  const [selectedDrivers, setSelectedDrivers] = useState(
    selectedDriversInitialState
  );

  if (!drivers) {
    return <p>Something went wrong</p>;
  }

  if (drivers.length === 0) {
    return <p>No Data found</p>;
  }

  const driversClass = new ArrayFilters(drivers);

  const columns = [
    {
      Header: "ID",
      accessor: "employeeId",
      Filter: ColumnFilter,
    },
    {
      Header: "Name",
      accessor: "fullName",
      Filter: ColumnFilter,
      width: 150,
      Cell: ({ value }) => NoteCell(value),
    },
    {
      Header: "Driver Type",
      accessor: "driverTypeName",
      Filter: ColumnFilter,
    },
    {
      Header: "License No.",
      accessor: "licenseNo",
      Filter: ColumnFilter,
    },
    {
      Header: "License Exp. Status",
      accessor: "licenseExpiryDateStr",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        const { color, tooltip, background } = getExpirationColor(value);
        return (
          <div
            className="d-flex justify-content-center align-items-center py-2"
            style={{ color, background, width: " 100%" }}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={value}
          >
            {tooltip}
          </div>
        );
      },
    },
    {
      Header: "Contact No.",
      accessor: "contactNo",
      Filter: ColumnFilter,
    },
    {
      Header: "MSIC",
      accessor: "msic",
      Filter: ColumnFilter,
    },
    {
      Header: "Groups",
      accessor: "driverGroupName",
      Filter: ColumnFilter,
      width: 250,
      Cell: ({ value }) => NoteCell(value),
    },
    {
      Header: "SWIFT TMP",
      accessor: "trafficRulesSignedOff",
      disableFilters: true,
      width: 100,
      Cell: ({ row: { original } }) => (
        <DownloadDocumentButton
          docName={original?.trafficRulesSignedOff}
          userId={original?.userId}
          url={
            config.userDocuments.downloadFile +
            "/" +
            original?.userId +
            "/" +
            "traffic-mng-sign-off.pdf"
          }
          fileName="traffic-mng-sign-off.pdf"
        />
      ),
    },
    {
      Header: wrapCell("Driving License"),
      accessor: "driversLicenceUrl",
      disableFilters: true,
      width: 100,
      Cell: ({ row: { original } }) => (
        <DownloadDocumentButton
          docName={original?.driversLicenceUrl}
          userId={original?.userId}
        />
      ),
    },
    {
      Header: "Passport",
      accessor: "",
      disableFilters: true,
      width: 100,
      Cell: ({ row: { original } }) => (
        <DownloadDocumentButton
          docName={original?.passportUrl}
          userId={original?.userId}
        />
      ),
    },
    {
      Header: "Medical",
      accessor: "",
      disableFilters: true,
      width: 100,
      Cell: ({ row: { original } }) => (
        <DownloadDocumentButton
          docName={original?.medicalUrl}
          userId={original?.userId}
        />
      ),
    },
    {
      Header: "License Back",
      accessor: "",
      disableFilters: true,
      width: 100,
      Cell: ({ row: { original } }) => (
        <DownloadDocumentButton
          docName={original?.abnUrl}
          userId={original?.userId}
        />
      ),
    },
    {
      Header: "Assessment",
      accessor: "",
      disableFilters: true,
      width: 100,
      Cell: ({ row: { original } }) => (
        <DownloadDocumentButton
          docName={original?.driverAssessmentUrl}
          userId={original?.userId}
        />
      ),
    },
    {
      Header: wrapCell("Subcontract Form"),
      accessor: "subcontractorFormUrl",
      disableFilters: true,
      width: 110,
      Cell: ({ row: { original } }) => (
        <DownloadDocumentButton docName={original?.subcontractorFormUrl} />
      ),
    },
    generateDriversEditViewDeleteColumn(
      "userId",
      false,
      driversTableClickEvents
    ),
  ];

  const driversLicenseExpiryData = driversClass.getDataForExpiredDrivers();

  return (
    <Container fluid className="drivers-dashboard-wrapper">
      <SelectedDriversModal
        onHide={() => setSelectedDrivers(selectedDriversInitialState)}
        selectedDrivers={selectedDrivers}
        columns={columns}
      />
      <h1>Drivers Dashboard</h1>
      <hr />
      <Tabs defaultActiveKey="table" id="uncontrolled-tab-example">
        <Tab title="Driver Records" eventKey="table">
          <div className="drivers-table row mt-4">
            <TableComp columns={columns} data={drivers} />
          </div>
        </Tab>
        <Tab title="Overview Report" eventKey="graph">
          <div className="row">
            <div className="col-md-3">
              <DriversDetailCard title="Total Drivers" value={drivers.length} />
            </div>
            <div className="col-md-3">
              <DriversDetailCard title="Jobs Done" value={drivers.length} />
            </div>
            <div className="col-md-3">
              <DriversDetailCard
                title="Manual Run-sheet (Drivers not logged In)"
                value={drivers.length}
              >
                <NotInRunsheetsDriversModal
                  header="Manual Run-sheet (Drivers not logged In)"
                  endpoint="/dashboard/driver/logging-details/manual-runsheet/not-exists"
                />
              </DriversDetailCard>
            </div>
            <div className="col-md-3">
              <DriversDetailCard
                title="Auto Run-sheet (Drivers not logged In)"
                value={drivers.length}
              >
                <NotInRunsheetsDriversModal
                  header="Auto Run-sheet (Drivers not logged In)"
                  endpoint="/dashboard/driver/logging-details/auto-runsheet/not-exists"
                />
              </DriversDetailCard>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-4">
              <div className="chart-container-500 card w-100 shadow-sm">
                <h6 className="card-subtitle chart-subtitle">
                  Drivers License Expiry ratio
                </h6>
                <LicenseExpiryRatioPie
                  data={[
                    {
                      id: "expired",
                      label: "Expired Licenses",
                      value: driversLicenseExpiryData.expired.length,
                    },
                    {
                      id: "unexpired",
                      label: "Active Licenses",
                      value: driversLicenseExpiryData.unexpired.length,
                    },
                  ]}
                  onClick={({ id, label }) => {
                    setSelectedDrivers({
                      drivers: driversLicenseExpiryData[id],
                      header: label,
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-md-8">
              <LineGraphWithOptions
                className="chart-container-500"
                title="Distance covered"
                value="totalKMDistanceCovered"
                url="/dashboard/driver/total-km-distance-covered"
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-7">
              <LineGraphWithOptions
                title="Jobs done"
                value="totalJobsCompleted"
                url="/dashboard/driver/total-jobs-completed"
              />
            </div>
            <div className="col-md-5">
              <div className="chart-container-400 card w-100 shadow-sm">
                <h6 className="card-subtitle chart-subtitle">
                  Employment Types
                </h6>
                <GroupByColumnPie
                  onClick={({ id }) => {
                    setSelectedDrivers({
                      drivers: driversClass.filterByColumn("employeeType", id),
                      header: `Employee Type - ${id}`,
                    });
                  }}
                  data={driversClass.groupDataByColumn("employeeType")}
                  theme="pink_yellowGreen"
                />
              </div>
            </div>
            <div className="col-md-5 mt-4">
              <VehiclesTypeChart />
            </div>
            <div className="col-md-7 mt-4">
              <div className="chart-container-400 card w-100 shadow-sm">
                <h6 className="card-subtitle chart-subtitle">Driver Groups</h6>
                <GroupByColumnPie
                  theme="purpleRed_green"
                  data={driversClass.groupDataByColumn("driverGroupName")}
                  onClick={({ id }) => {
                    setSelectedDrivers({
                      drivers: driversClass.filterByColumn(
                        "driverGroupName",
                        id
                      ),
                      header: `Driver Group - ${id}`,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </Tab>
      </Tabs>
    </Container>
  );
}
