import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Navbar from "./components/Navbar";
import config from "./constants/config";
import {
  AUTO_RUNSHEET,
  DRIVERS,
  EXPORT_RELEASE_PLANNER,
  FINANCE_APPLICATION,
  INDUCTION,
  INSPECTION_REPORT,
  MANUAL_RUNSHEET,
  REPORTING_TOOL,
  SURCHARGE_MAINTENANCE,
  USER_MANAGEMENT,
  VEHICLES,
} from "./constants/enums";
import * as Navigation from "./constants/routes";
import {
  AutoRunsheet,
  DriversDashboardPage,
  DriversOperations,
  EnigmaChat,
  ExportReleasePlanner,
  ExportReleasePlannerActions,
  InductionDashboard,
  InspectionReport,
  ManualRunsheet,
  Reporting,
  UserManagement,
  Vehicles,
  VehiclesAdvanceSearch,
  VehiclesDashboard,
  SurchargeMaintenance,
  FinanceApplication,
} from "./features";
import CreateUser from "./features/UserManagement/CreateUser";
import Customers from "./features/FinanceApplication/components/customers/Customers";
import Finance from "./features/FinanceApplication/components/finance/Finance";
import InductionCreateUser from "./features/induction/InductionCreateUser";
import VehiclesActions from "./features/vehicles/actions/loadable";
import {
  fetchProfileDetails,
  getAllLookUpsData,
  getDriverList,
  getDriverTypeList,
  getLookUpValues,
  getUserData,
  getVehicleTypeList,
  selectServerEnvironment,
  setEnvironment,
  setUserData,
} from "./rtk-slice/globalSlice";
import { getDriverGroup } from "./rtk-slice/userManagement";
import { hasModuleAccess } from "./utils";
import CreditApplicationForm from "./components/CreditApplication/CreditApplicationForm";

const App = () => {
  const dispatch = useDispatch();
  const userData = useSelector(getUserData);
  const environment = useSelector(selectServerEnvironment);
  const user = JSON.parse(localStorage.getItem("userdetails"));

  if (environment === "") {
    dispatch(setEnvironment(config.hostsEnvironment[window.location.host]));
  }

  useEffect(() => {
    if (userData?.userContext?.tokenId) {
      dispatch(getDriverList());
      dispatch(getDriverGroup());
      dispatch(fetchProfileDetails());
      dispatch(getVehicleTypeList());
      dispatch(getLookUpValues());
      dispatch(getAllLookUpsData());
      dispatch(getDriverTypeList());
    }
  }, [dispatch, userData]);

  if (!user?.userContext?.tokenId) {
    return <Navigate to={Navigation.LOGIN} />;
  } else {
    if (!userData) {
      dispatch(setUserData(user));
    }
  }

  return (
    <div className="App">
      <Navbar />

      <Outlet />
      <Routes>
        <Route exact path={Navigation.HOME} element={<Dashboard />} />
        {/* Manual Runsheet */}
        {hasModuleAccess(MANUAL_RUNSHEET) && (
          <Route
            exact
            path={Navigation.MANUAL_RUNSHEET}
            element={<ManualRunsheet />}
          />
        )}
        {["dev", "local"].includes(environment) &&
          hasModuleAccess(AUTO_RUNSHEET) && (
            <Route
              exact
              path={Navigation.AUTO_RUNSHEET}
              element={<AutoRunsheet />}
            />
          )}
        {hasModuleAccess(USER_MANAGEMENT) && (
          <Route exact path={Navigation.USER_MANAGEMENT}>
            <Route index element={<UserManagement />} />
            <Route path={Navigation.CREATE_USER} element={<CreateUser />} />
          </Route>
        )}
        {hasModuleAccess(INDUCTION) && (
          <Route exact path={Navigation.INDUCTION}>
            <Route index element={<InductionDashboard />} />
            <Route
              path={Navigation.CREATE_INDUCTION_USER}
              element={<InductionCreateUser />}
            />
          </Route>
        )}
        {hasModuleAccess(INSPECTION_REPORT) && (
          <Route
            exact
            path={Navigation.INSPECTION_REPORT}
            element={<InspectionReport />}
          />
        )}
        {hasModuleAccess(VEHICLES) && (
          <Route path={Navigation.VEHICLES}>
            <Route index element={<Vehicles />} />
            <Route
              path={Navigation.VEHICLE_ACTION}
              element={<VehiclesActions />}
            />
            <Route path="dashboard" element={<VehiclesDashboard />} />
            <Route
              path={Navigation.VEHICLES_ADVANCE_SEARCH}
              element={<VehiclesAdvanceSearch />}
            />
          </Route>
        )}
        {hasModuleAccess(EXPORT_RELEASE_PLANNER) && (
          <Route path={Navigation.EXPORT_RELEASE_PLANNER}>
            <Route index element={<ExportReleasePlanner />} />
            <Route
              path={Navigation.EXPORT_RELEASE_PLANNER_ACTION}
              element={<ExportReleasePlannerActions />}
            />
          </Route>
        )}
        {["dev", "local"].includes?.(environment) && (
          <Route path="/enigma-chat">
            <Route index element={<EnigmaChat />} />
          </Route>
        )}
        {hasModuleAccess(DRIVERS) && (
          <>
            <Route path={Navigation.DRIVERS_DASHBOARD}>
              <Route index element={<DriversDashboardPage />} />
            </Route>
            <Route path={Navigation.DRIVERS_OPERATIONS}>
              <Route index element={<DriversOperations />} />
            </Route>
          </>
        )}
        {hasModuleAccess(REPORTING_TOOL) && (
          <Route path={Navigation.REPORTING}>
            <Route index element={<Reporting />} />
          </Route>
        )}
        {hasModuleAccess(SURCHARGE_MAINTENANCE) && (
          <Route path={Navigation.SURCHARGE_MAINTENANCE}>
            <Route index element={<SurchargeMaintenance />} />
          </Route>
        )}
        {hasModuleAccess(FINANCE_APPLICATION) && (
          <>
            <Route exact path={Navigation.FINANCE_APPLICATION}>
              <Route index element={<FinanceApplication />} />
              <Route path={Navigation.CUSTOMERS} element={<Customers />} />
              <Route path={Navigation.FINANCE} element={<Finance />} />
            </Route>
            <Route path={Navigation.FINANCE_APPLICATION_CUSTOMER}>
              <Route
                path={Navigation.EditCreditApplication}
                element={<CreditApplicationForm />}
              />
              <Route
                path={Navigation.VIEW_CREDIT_APPLICATION_WITH_ID}
                element={<CreditApplicationForm />}
              />
            </Route>
          </>
        )}
      </Routes>
    </div>
  );
};

export default App;
