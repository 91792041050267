import React, { createContext, useState } from "react";
import { useSelector } from "react-redux";
import { selectVehicleLoading } from "../../rtk-slice/vehiclesSlice";
import Spinner from "../Spinner/Spinner";

export const LoadingContext = createContext({
  loading: false,
  setLoading: () => undefined,
});

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const vehicleLoading = useSelector(selectVehicleLoading)
  const exportLoading = useSelector((state) => state.export.loading)
  const loadingHelper = {
    loading,
    setLoading,
  };

  return (
    <LoadingContext.Provider value={loadingHelper}>
      {children}
      {(loading || vehicleLoading || exportLoading) && <Spinner />}
    </LoadingContext.Provider>
  );
};
