import { Routes, Route } from "react-router-dom";
import * as Navigation from "./constants/routes";
import Login from "./components/Login/LoginComp";
import PrivacyPolicy from "./components/PrivacyPolicy";
import App from "./App";
import CreditApplicationForm from "./components/CreditApplication/CreditApplicationForm";
import SwiftTrafficSignup from "./features/SwiftTrafficSignup";

const routes = [
  { path: Navigation.LOGIN, element: <Login /> },
  { path: Navigation.PRIVACY_POLICY, element: <PrivacyPolicy /> },
  { path: Navigation.CREDIT_APPLICATION, element: <CreditApplicationForm /> },
  {
    path: Navigation.CREDIT_APPLICATION_WITH_ID,
    element: <CreditApplicationForm />,
  },
  {
    path: Navigation.VIEW_CREDIT_APPLICATION_WITH_ID,
    element: <CreditApplicationForm />,
  },
  {
    path: Navigation.SWIFT_TRAFFIC_SIGNUP,
    element: <SwiftTrafficSignup />,
  },
  { path: "*", element: <App /> },
];

const AppRoutes = () => {
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
};

export default AppRoutes;
