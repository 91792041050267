import { SUPER_ADMIN } from "../constants/enums";
import startCase from "lodash/startCase";
import { ColumnFilter } from "../features/UserManagement/UsersDisplay";
import { FaEye, FaPen, FaTrash } from "react-icons/fa";
import { TiDocumentDelete } from "react-icons/ti";
import { Link } from "react-router-dom";

/**
 * It returns an array of objects with the format { name: '2019 - 2020', value: 2019 } for the last 20
 * years
 */
export const getYearRange = () =>
  Array.from({ length: 20 }, (_, i) => {
    const addition = new Date().getMonth() >= 6 ? 1 : 0;
    const start = new Date().getFullYear() + addition;
    return { name: `${start - i - 1} - ${start - i}`, value: start - i - 1 };
  });

/**
 * It creates a link element, sets the href to the url, sets the download attribute to the name,
 * appends the link to the body, clicks the link, and then removes the link from the body
 */
export const downloadWithUrl = ({ name, url }) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${name}`);
  // Append to html link element page
  document.body.appendChild(link);
  // Start download
  link.click();
  // Clean up and remove the link
  link.parentNode.removeChild(link);
};

/**
 * It takes a data URL and returns a File object
 * @param dataurl - The data URL of the image.
 * @param filename - The name of the file.
 * @returns A file object
 */
export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

/**
 * It takes a base64 string, converts it to a byte array, and then creates a blob from that byte array
 * @param b64Data - The base64 encoded data.
 * @param [contentType] - The MIME type of the file.
 * @param [sliceSize=512] - The size of each chunk;
 * @returns A blob object
 */
export default function b64toBlob(b64Data, contentType = "", sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

/**
 * It takes an email address as a string, converts it to lowercase, and returns true if it matches the
 * regex pattern, and false if it doesn't
 * @param email - The email address to validate.
 * @returns A boolean value.
 */
export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

/**
 * It takes a string and a bisector, and returns a list of the string split at the bisector
 * @param str - the string to be bisected
 * @param bisector - the string that separates the two strings
 */
export const stringBisector = (str, bisector) => {
  if (!str) return "", "";
  const arr = str.split(":");
  const firstHalf = arr[0];
  const secondHalf = arr.slice(1, arr.length);
  return [firstHalf, secondHalf];
};

export const hasModuleAccess = (module) => {
  if (process.env.NODE_ENV === "development") return true;

  const userDetails = JSON.parse(
    localStorage.getItem("userdetails")
  )?.userDetails;

  if (userDetails?.userModules === undefined) return false;

  return (
    module in userDetails?.userModules || userDetails?.role === SUPER_ADMIN
  );
};

export function formatDate(interval) {
  const currentDate = new Date();

  switch (interval) {
    case "weekBefore":
      currentDate.setDate(currentDate.getDate() - 7);
      break;
    case "monthBefore":
      currentDate.setMonth(currentDate.getMonth() - 1);
      break;
    case "sixMonthsBefore":
      currentDate.setMonth(currentDate.getMonth() - 6);
      break;
    case "yearBefore":
      currentDate.setFullYear(currentDate.getFullYear() - 1);
      break;
    default:
      // If an invalid interval is provided, return the current date
      break;
  }

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function generateTableColumns(obj) {
  return Object.keys(obj).map((key) => ({
    Header: startCase(key),
    accessor: key,
    Filter: ColumnFilter,
  }));
}

export function generateDriversEditViewDeleteColumn(
  key,
  hideDelete = false,
  handleActionClick
) {
  const ACTION_ICON_SIZE = 18;
  return {
    disableFilters: true,
    Header: "Actions",
    width: 100,
    Cell: ({ row }) => (
      <div className="d-flex">
        <Link
          to={`/drivers-operation/view/${row.original[key]}`}
          title="view"
          className="btn btn-primary p-1 me-1"
        >
          <FaEye size={ACTION_ICON_SIZE} />
        </Link>
        <Link
          to={`/drivers-operation/edit/${row.original[key]}`}
          title="Edit"
          className="btn btn-primary p-1 me-1"
        >
          <FaPen className="" size={ACTION_ICON_SIZE} />
        </Link>

        {hideDelete ? null : (
          <button
            title={row.original.active ? "Deactivate" : "Activate"}
            className={`btn ${
              row.original.active ? "btn-danger" : "btn-warning"
            } p-1`}
            onClick={() => {
              handleActionClick(row);
            }}
          >
            {row.original.active ? (
              <FaTrash size={ACTION_ICON_SIZE} />
            ) : (
              <TiDocumentDelete size={ACTION_ICON_SIZE} />
            )}
          </button>
        )}
      </div>
    ),
  };
}
