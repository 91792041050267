import React, { useMemo } from "react";
import { FaEye, FaPen, FaTrash } from "react-icons/fa";
import isEmpty from "lodash/isEmpty";
import TableComp from "../../../../../components/Table";
import { fetchService } from "../../../../../utils/api";
import useLoading from "../../../../../components/hooks/useLoading";
import { errorToast } from "../../../../../utils/toastFunc";
import { useDispatch } from "react-redux";
import { deleteVehicleDetail } from "../../../../../rtk-slice/vehiclesSlice";

const actionIconSize = 18;

export default function DetailsTable({
  details,
  activeTab,
  setSelectedDetails,
  setDetails,
  selectedDetails,
  controller,
}) {
  const { setLoading } = useLoading();
  const dispatch = useDispatch();

  const handleActionClick = (event, data, index) => {
    switch (event) {
      case "view":
        setSelectedDetails({
          mode: "view",
          data,
        });
        break;

      case "edit":
        setSelectedDetails({
          mode: "edit",
          data,
        });
        break;

      case "delete": {
        const { tabId, uniqueReferenceKey } = activeTab;
        if (controller === "vehicles") {
          if (
            selectedDetails.data[uniqueReferenceKey] ===
            data[uniqueReferenceKey]
          ) {
            setSelectedDetails({
              mode: "",
              data: {},
            });
          }
          dispatch(deleteVehicleDetail({ data, tab: activeTab, index }));
        } else {
          setLoading(true);

          fetchService({
            url: `${activeTab.url}/${data[uniqueReferenceKey]}`,
            method: "DELETE",
            onSuccessCallback: (response) => {
              if (response) {
                if (
                  selectedDetails.data[uniqueReferenceKey] ===
                  data[uniqueReferenceKey]
                ) {
                  setSelectedDetails({
                    mode: "",
                    data: {},
                  });
                }

                setDetails((prevDetails) => {
                  const tab = [...prevDetails[tabId]];
                  tab.splice(index, 1);
                  return { ...prevDetails, [tabId]: tab };
                });
              }
            },
          })
            .catch((error) => {
              errorToast({ mes: error?.message ?? "Something went wrong!" });
            })
            .finally(() => {
              setLoading(false);
            });
        }
        break;
      }

      default:
        break;
    }
  };

  const columns = useMemo(() => {
    const getFieldValue = ({ value }) => {
      if (typeof value === "boolean") return value ? "YES" : "NO";
      return value;
    };

    const cols = activeTab.formFields
      .filter((field) => !field.hideInTableMode)
      .map((field) => ({
        Cell: (col) => getFieldValue(col),
        Header: field.label,
        accessor: field.fieldId,
        disableFilters: true,
        width: 60,
      }));

    cols.push({
      disableFilters: true,
      maxWidth: 70,
      minWidth: 50,
      Header: "Actions",
      width: 65,
      Cell: ({ row, ...prop }) => (
        <div className="d-flex ">
          <button
            title="view"
            className="btn btn-primary p-1 me-1 lh-1"
            onClick={() => {
              handleActionClick("view", row.original);
            }}
          >
            <FaEye size={actionIconSize} />
          </button>
          <button
            title="edit"
            className="btn btn-primary p-1 me-1"
            onClick={() => {
              handleActionClick("edit", row.original);
            }}
          >
            <FaPen className="" size={actionIconSize} />
          </button>

          <button
            title={row.original.active ? "Deactivate" : "Activate"}
            className="btn btn-danger p-1"
            onClick={() => {
              handleActionClick("delete", row.original, row.index);
            }}
          >
            <FaTrash size={actionIconSize} />
          </button>
        </div>
      ),
    });

    return cols;
  }, [activeTab, selectedDetails]);

  if (
    isEmpty(activeTab) ||
    isEmpty(details[activeTab.tabId]) ||
    isEmpty(columns)
  )
    return null;

  return (
    <TableComp
      data={details[activeTab.tabId]}
      columns={columns}
      getRowProps={(row) => ({
        style: {
          backgroundColor:
            selectedDetails.data[activeTab.uniqueReferenceKey] ===
            row.original[activeTab.uniqueReferenceKey]
              ? "#f1fc8b"
              : "",
        },
      })}
    />
  );
}
