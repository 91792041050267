import React, { useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import './Modal.scss'
/**
 * It's a function that returns a modal component with a title, body, footer, header, and a close
 * button
 */
const ModalTab = ({
  size,
  show,
  onHide,
  title,
  body,
  footer,
  header,
  bodyClassName,
  ...props
}) => {
  return (
    // <Modal size={size} show={show} onHide={onHide} {...props} dialogClassName="modal__bg">
    <Modal size={size} show={show} onHide={onHide} {...props}>
      <div
        className="d-flex pb-1 align-items-center"
        style={{
          background: `linear-gradient(
      210.96deg,
      rgba(17, 50, 80, 0.61) 0.01%,
      rgba(17, 50, 80, 0.7) 42.05%,
      rgba(17, 50, 80, 0) 104.81%
    )`,
        }}
      >
        <h4 className="me-auto mx-3 mt-4 text-center ">{title}</h4>
        <button
          type="button"
          aria-label="close"
          className="btn-close  close-button"
          onClick={onHide}
        ></button>
      </div>
      {header && <Modal.Header>{header}</Modal.Header>}
      {body && (
        <Modal.Body className={`text-center p-2 ${bodyClassName}`}>
          {body}
        </Modal.Body>
      )}
      {footer && <Modal.Footer className="text-center">{footer}</Modal.Footer>}
    </Modal>
  );
};


function ConfirmModal(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleConfirm = () => {
    props.onConfirm();
    handleClose();
  }

  return (
    <>
      <Button className="buttons" onClick={handleShow} title={props.tooltip}>
        {props.button}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" className="buttons" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export  {ConfirmModal} 

export default ModalTab;
